/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import '@fontsource/anek-latin/variable-full.css';
import '@fontsource/playfair-display';
import React from 'react';
import Layout from './src/components/layout';
import { MDXProvider } from '@mdx-js/react';
import { ThemeProvider, useThemeUI } from 'theme-ui';
import theme from './src/gatsby-plugin-theme-ui';

import './src/styles/global.css';

export const wrapPageElement = ({ element, props, components }) => {
  return (
    <MDXProvider>
      <ThemeProvider theme={theme}>
        <Layout {...props}>{element}</Layout>
      </ThemeProvider>
    </MDXProvider>
  );
};

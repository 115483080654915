import * as React from 'react';
import { Link } from 'gatsby';
import { Box } from 'theme-ui';

const Header = ({ siteTitle }) => (
  <Box
    as="header"
    sx={{
      margin: `0 auto`,
      maxWidth: 'container',
      display: `flex`,
      alignItems: `center`,
      justifyContent: `space-between`,
      px: 3,
    }}
  ></Box>
);

export default Header;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Header from './header';
import { Box, Flex, NavLink, Text, useThemeUI } from 'theme-ui';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { mediaQueryMobile } from '../styles/mixins';

const Layout = ({ children }) => {
  return (
    <>
      <Flex
        sx={{
          margin: `0 auto`,
          maxWidth: `container`,
          my: [0, 5, 5],
          px: 3,
          flexDirection: ['column', 'row', 'row'],
        }}
      >
        <Navbar>
          <Box
            sx={{
              flexDirection: ['row', 'column', 'column'],
              display: 'flex',
              position: 'sticky',
              top: 3,
            }}
          >
            <NavItem to="/">Me</NavItem>
            {/* <NavItem to="/blog/">Blog</NavItem> */}
            <NavItem to="/contact/">Contact</NavItem>
          </Box>
        </Navbar>
        <Box as="main" sx={{ flex: 1 }}>
          {children}
        </Box>
        {/* <footer
          style={{
            marginTop: `var(--space-5)`,
            fontSize: `var(--font-sm)`,
          }}
        >
          © {new Date().getFullYear()} &middot; Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer> */}
      </Flex>
    </>
  );
};

const Navbar = styled.aside`
  width: 150px;
  margin-right: 48px;
  ${mediaQueryMobile} {
    width: 100%;
    margin: 12px 0;
  }
`;

const NavItem = ({ children, ...rest }) => {
  const { theme } = useThemeUI();
  return (
    <Box>
      <NavLink
        activeStyle={{
          color: theme.colors.heading,
          fontWeight: theme.fontWeights.bold,
          background: 'rgba(0, 0, 0, 0.04)',
          borderRadius: '8px',
        }}
        style={{ display: 'block' }}
        as={Link}
        {...rest}
      >
        {children}
      </NavLink>
    </Box>
  );
};

export default Layout;

export default {
  colors: {
    heading: '#111',
    background: '#fff',
    primary: '#137736',
    text: '#292929',
    muted: '#929292',
    highlight: '#e3e3e3',
    secondary: '#ff6347',
  },
  fonts: {
    body: [
      '"Anek Variable"',
      '"Anek LatinVariable"',
      '"Helvetica Neue"',
      'Roboto',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    heading: ['Playfair Display', 'serif', 'system-ui'].join(','),
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    tighter: '-0.025em',
    tight: '-0.025em',
    loose: '0.0125em',
    looser: '0.02em',
  },
  fontSizes: [12, 16, 18, 20, 24, 32, 48, 64, 72],
  radii: [1, 2, 4, 8, 16, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: 768,
    narrow: 512,
  },
  text: {
    heading: {
      fontSize: 5,
      fontFamily: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'looser',
      color: 'heading',
    },
    subheading: {
      fontSize: 3,
      lineHeight: 'heading',
      letterSpacing: 'loose',
      //   color: "primary",
    },
    bodyTitle: {
      fontSize: 3,
      fontFamily: 'heading',
      lineHeight: 'heading',
      letterSpacing: 'looser',
      color: 'heading',
    },
    body: {
      fontSize: 2,
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      letterSpacing: 'looser',
      color: 'body',
    },
    caption: {
      fontSize: 2,
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      letterSpacing: 'loose',
      color: 'muted',
    },
  },
  links: {
    nav: {
      fontSize: 3,
      fontFamily: 'heading',
      color: 'muted',
      textDecoration: 'none',
      p: 2,
      mb: 2,
      mr: 2,
    },
    small: {
      fontSize: 1,
      color: 'primary',
    },
  },
};
